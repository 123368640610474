<template>
  <div class="list_box">
    <LEFTNAV></LEFTNAV>
    <div class="box" v-bind:style="{ minHeight: Height + 'px' }">
      <div style="background: white;">
          <div class="box_part" v-bind:style="{ minHeight: Height - 40 + 'px' }">
        <div class="box_des">
          <div class="box_top">
            <div class="txt">
              <h1>
                <span>scholarcy </span>
                <br />
                — —AI智能高效阅读工具
              </h1>
              <h3>
                将您的PDF文件上传至Scholarcy，我们会将您的文件进行内容要点切分提炼后返回为Word文档给您！
              </h3>
            </div>
            <div class="top_img">
              <img src="../../assets/imgs/pc-Scholarcy/首图.png.png" alt="" />
            </div>
          </div>
          <div class="box_jieshao">
            <div class="card" style="margin: 20px 20px 20px 0;">
              
              <div class="tit_top">
                <div class="tit_left">
                  <img
                    src="../../assets/imgs/pc-Scholarcy/分组 2.png.png"
                    alt=""
                  />
                  <div class="words">
                    <h2>SCHOLARCY体验馆</h2>
                    <p>产品介绍人，盈科小客服</p>
                  </div>
                </div>
                <div class="tit_right">
                  <img
                    src="../../assets/imgs/pc-Scholarcy/引号图标.png"
                    alt=""
                  />
                </div>
              </div>
              <p class="p_box">
                智能高校的阅读工具Scholarcy运用Robot-Highlighter
                <i class="top">TM</i> 技术，
                将文献内容进行要点提炼并同步翻译成中文；提炼要点全面包括摘要、关键点、概要、简介、研究方法、
                研究结果、讨论、相关工作、总结、基金、参与人员与数据统计、参考文献、笔记备注等内容。您的文档可在任何设备上访问。
                从 PDF 文件创建摘要卡，并导出到 Word ！
              </p>
            </div>
            <div class="card"  style="margin: 20px 0 20px 20px;">
              <div class="tit_top">
                <div class="tit_left">
                  <img
                    src="../../assets/imgs/pc-Scholarcy/分组 2.png.png"
                    alt=""
                  />
                  <div class="words">
                    <h2>SCHOLARCY提示官</h2>
                    <p style="font-weight: 600; letter-spacing: 2px">
                      产品使用提示
                    </p>
                  </div>
                </div>
                <div class="tit_right">
                  <img
                    src="../../assets/imgs/pc-Scholarcy/引号图标.png"
                    alt=""
                  />
                </div>
              </div>
              <p class="p_box">
                1.仅支持英语期刊原创论文（Articles）的切分提取翻译 <br />
                2.上传文档大小必须小于 30MB
                <br />3.支持多条文档同时上传，文件上传仅限于支持pdf格式文件
                <br />4.上传扫描版PDF可能将会影响您的使用体验
              </p>
            </div>
          </div>
          <div class="box_hengfu">
            <div class="txt_left">
              <div class="line"></div>
              <p>
                SCHOLARCY从PDF文件创建摘要卡 , 并导出 Word !
                其曾于2020年荣获"研习与专业领域出版机构协会 ( ALPSP ) 出版创新奖
                ".
              </p>
            </div>
            <div class="img_right">
              <img src="../../assets/imgs/pc-Scholarcy/小组件.png" alt="" />
            </div>
          </div>
          <div class="box_serve">
            <h2>服务特点</h2>
            <div class="line"></div>
            <div class="serves">
              <div>
                <div class="serve">
                  <div class="ser_img">
                    <img
                      src="../../assets/imgs/pc-Scholarcy/图标1.png"
                      alt=""
                    />
                  </div>

                  <p>自动划出文献重点, 提高文献阅读效率</p>
                </div>
                <div class="serve">
                  <div class="ser_img">
                    <img
                      src="../../assets/imgs/pc-Scholarcy/图标2.png"
                      alt=""
                    />
                  </div>

                  <p>快速提取文献精华,保留原文内容不改写</p>
                </div>
              </div>
              <div>
                <div class="serve">
                  <div class="ser_img">
                    <img
                      src="../../assets/imgs/pc-Scholarcy/图标3.png"
                      alt=""
                    />
                  </div>

                  <p>全面理解研究脉络,适合可重复性研究</p>
                </div>

                <div class="serve">
                  <div class="ser_img">
                    <img
                      src="../../assets/imgs/pc-Scholarcy/图标4.png"
                      alt=""
                    />
                  </div>

                  <p>支持上传多篇文章,降低使用时间成本</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import LEFTNAV from "./component/leftNav.vue";
import {
  detail_list,
  input_file,
  download,
  get_limit,
} from "@/Api/scholarcy_writeful";
export default {
  data() {
    return {
      Height: 0,
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 140; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 140;
    };
  },
  created() {},
  beforeDestroy() {
    // 清空定时器
  },

  methods: {},
  components: {
    LEFTNAV,
  },
};
</script>

<style lang="less" scoped>
.list_box {
  padding-top: 75px;
  //   min-width: 1380px;
  //   height: 1500px;
  display: flex;
  color: #000000;
  //

  .box {
    padding: 20px;
    //
    width: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    
    .box_part {
      //   background: white;
      
      background: url("../../assets/imgs/pc-Scholarcy/背景 1.png");
      width: 100%; //大小设置为100%
      height: 100%; //大小设置为100%
      //   position: fixed;
      background-size: 100% 100%;
      //   padding: 20px;
      display: flex;
      justify-content: center;
      .box_des {
        //   margin: auto;
        // width: 1200px;
        width: 1200px;
        .box_top {
          //   width: 1200px;
          margin-top: 50px;
          display: flex;
          justify-content: space-around;
        //   height: 400px;
          // align-items: center;
          .txt {
            width: 650px;

            // font-family: "微软雅黑" !important;
            margin-top: 70px;
            h1 {
              font-size: 55px;
              line-height: 65px;
              span {
                font-size: 60px;
              }
            }
            h3 {
              font-size: 17px;
              margin-top: 30px;
              width: 580px;
              line-height: 25px;
              font-weight: 600;

              letter-spacing: 1px;
            }
          }
          .top_img {
            // width: 50%;
            margin-top: 60px;
            overflow: hidden;
            img {
              width: 500px;
              // height: 554px;
            }
          }
        }
      }
    }
  }
}
.box_jieshao {
  display: flex;
//   justify-content: space-between;
  .card {
    
    width: 50%;
    background: white;
    /* 阴影向右偏移10px、向下偏移10px、模糊距离为20px、阴影尺寸为10px、颜色为pink*/
    box-shadow: 0px 4px 20px 6px rgba(214, 223, 255, 1);
    padding: 20px;
    .tit_top {
      display: flex;
      justify-content: space-between;
      .tit_left {
        display: flex;
        img {
          width: 80px;
        }
        .words {
          margin: 10px;
          h2 {
            font-size: 23px;
          }
          p {
            font-size: 15px;
            font-weight: 600;
            color: #5e95fb;
            line-height: 30px;
          }
        }
      }
    }
    .p_box {
      margin: 20px 0 0 0;
      line-height: 25px;
    }
  }
}
.box_hengfu {
  background: #6434ff;
  height: 200px;
  display: flex;
  //   width: 1250px !important;
  justify-content: space-between;
  .txt_left {
    margin: 30px 0 0 50px;
    .line {
      margin-top: 20px;
      width: 80px;
      height: 2px;
      background: rgba(255, 255, 255, 1);
      background-blend-mode: normal;
      box-shadow: 0px 2px 0px rgba(106, 158, 255, 1);
    }
    p {
      margin-top: 10px;
      color: white;
      font-size: 18px;
      line-height: 36px;
      width: 650px;
      word-spacing: 1px;
      font-weight: 500;
      font-weight: 600;

      letter-spacing: 1px;
    }
  }
  .img_right {
    width: 380px;
    overflow: hidden;
    img {
      width: 300px;
      // height: 300px;
    }
  }
}
.box_serve {
  margin: 20px 0px 0 0px;
  h2 {
    line-height: 50px;
    font-size: 30px;
  }
  .line {
    // margin-top: 20px;
    border: 1px solid rgba(100, 52, 255, 1);
    width: 50px;
  }
  .serves {
    display: flex;
    justify-content: space-around;
    // margin-top: 30px;
    .serve {
      width: 550px;
      height: 100px;
      background: white;
      display: flex;
      margin: 20px 0;
      box-shadow: 0px 20px 60px rgba(49, 69, 244, 0.1);
      .ser_img {
        width: 100px;
        height: 100px;
        background: #6434ff;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 50px;
        }
      }

      p {
        margin-left: 20px;
        line-height: 100px;
        font-size: 20px;
        font-weight: 600;
        display: inline-block;
        letter-spacing: 1px;
      }
    }
  }
}
.top {
  font-size: 5px;
  position: relative;
  top: -6px;
  left: 0px;
  font-style: normal;
}
</style>